<template>
		<settingReduction :isRedutionIntegrate="true"></SettingReduction>
</template>
<script>
import SettingReduction from './setting-reduction'
export default {
	components: {
		SettingReduction
	},
};
</script>

 
      
    